<template>        
    <div class="products products--slider uk-position-relative uk-slider-container-offset" uk-slider="sets: true;">
        <div class="uk-visible-toggle uk-light" tabindex="-1">
            <div class="uk-slider-container">
                <ul class="uk-slider-items uk-child-width-1-5@m uk-child-width-1-2@s uk-grid">
                    <li  v-if="!productsLoading" v-for="product in products">
                        <product-card
                            :product="product"
                            :key="product.id"
                            :routes="routes"
                            :translations="translations"
                        >
                        </product-card>
                    </li>
                    <!-- Skeleton loading - products loading -->
                    <li v-if="!products"v-for="i in pwProductsCount" :key="i">
                        
                        <a href="javascript:void(0)" class="product product--placeholder">
                            <div class="product__header">
                                <img class="product__image" src="/dist/assets/images/placeholders/product-placeholder--thumb.jpg" alt="product">
                            </div>
                            <div class="product__info">
                                <p class="product__title">---</p>
                                <!-- <p class="body--small">---------</p> -->
                            </div>
                            <div class="product__footer">
                                <div>
                                    <div class="product__price mb-2">
                                        <!-- <p class="product__price--label">vanaf</p> -->
                                        <p class="product__price--current">--</p>
                                    </div>
                                </div>
                                <button class="product__cart">
                                    <img src="/dist/assets/images/icons/cart.svg" width="20" uk-svg />
                                </button>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import * as functions from "../functions.js";
    import { EventBus } from "../EventBus";
    import WishlistButton from "./WishlistButton";

    export default {
        name: "ProductsBlock",
        components: {WishlistButton},
        mounted() {
                this.pwProductsCount = this.pwProducts.length;
                this.initProducts();
        },
        data() {
            return {
                products: null,
                pwProductsCount: 0,
                productsLoading: true,
                isLoading: false,
            };
        },
        methods: {
            initProducts() {
                this.productsLoading = true;
                let data = {
                    pw_products: this.pwProducts,
                };

                window.axios.post(this.routes.getProducts, data)
                    .then(res => {
                        let products = res.data;
                        if(products && products.length > 0)
                        {
                            this.products = products;
                            this.productsLoading = false;
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        this.productsLoading = false;
                    });
            },
            cartClickHandler(product)
            {
              if(product.type === "basart") {
                EventBus.$emit("PRODUCT_CARD_CONFIGURATOR", product);
              } else {
                this.isLoading = true;

                window.axios.post(this.routes.products.addToCart, {product_id: product.id, quantity: 1})
                    .then(res => {
                      EventBus.$emit('CART_UPDATE', res.data.cart);
                      EventBus.$emit('PRODUCT_ADDED_MODAL', {product: product, quantity: 1});
                      this.isLoading = false;
                    })
                    .catch(error => {
                      let message = {
                        message: this.translations.add_error,
                        pos: "top-right",
                        status: "danger",
                        timeout: 3000,
                      };

                      EventBus.$emit('NOTIFY', message);
                      this.isLoading = false;
                    });
              }
            },
        },
        props: {
            pwProducts: {
                type: Object|Array,
                required: true,
            },
            routes: {
                type: Object|Array,
                required: true,
            },
            translations: {
                type: Object|Array,
                required: true,
            }
        }
    }
</script>

<style scoped>

</style>