var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "uk-text-center mt-sm-5 mt-4" }, [
    _c(
      "div",
      { staticClass: "loader", class: !_vm.isLoading ? "uk-invisible" : "" },
      [_c("i", { staticClass: "fa-solid fa-circle-notch fa-spin" })]
    ),
    _vm._v(" "),
    _c(
      "ul",
      {
        staticClass: "uk-pagination uk-flex-center",
        attrs: { "uk-margin": "" },
      },
      [
        _vm.currentPage != 1
          ? _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.handleClick(_vm.currentPage - 1)
                  },
                },
              },
              [_vm._m(0)]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.pagesArray, function (page) {
          return _c(
            "li",
            {
              class: page.class,
              on: {
                click: function ($event) {
                  return _vm.handleClick(page.page)
                },
              },
            },
            [
              page.class
                ? _c("span", [_vm._v(_vm._s(page.page))])
                : _c("a", { attrs: { href: "javascript:void(0)" } }, [
                    _vm._v(_vm._s(page.page)),
                  ]),
            ]
          )
        }),
        _vm._v(" "),
        _vm.currentPage != _vm.lastPage
          ? _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.handleClick(_vm.currentPage + 1)
                  },
                },
              },
              [_vm._m(1)]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "javascript:void(0)" } }, [
      _c("span", { attrs: { "uk-pagination-previous": "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "javascript:void(0)" } }, [
      _c("span", { attrs: { "uk-pagination-next": "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }