var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { staticClass: "cart-button", attrs: { href: _vm.routes.overview } },
    [
      _c(
        "span",
        { staticClass: "amount", staticStyle: { position: "absolute" } },
        [_vm._v(_vm._s(_vm.count))]
      ),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/dist/assets/images/icons/heart.svg",
          alt: "heart icon",
          draggable: "false",
          "uk-svg": "",
        },
      }),
      _vm._v(" "),
      _c("span", [_vm._v("Verlanglijstje")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }