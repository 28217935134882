<template>
    <div class="mb-md-0 mb-5">
        <a :href="product.slug" :title="product.name" class="product">
            <span class="product__header">
                <img class="product__image" :src="imageUrl" :alt="product.name">
                <!--<div v-if="product.discount_label != null" class="image-banner">{{ product.discount_label }}</div>-->
                
                <wishlist-button
                    :in-wishlist="product.in_wishlist"
                    :product-id="product.id"
                    :routes="routes"
                ></wishlist-button>
            </span>

            <div class="product__info">
                <p class="product__title">{{ product.name }}</p>

                <div class="check-list" v-if="product.trumps && product.trumps.length">
                    <ul class="check-list body-small">
                        <li class="mb-1" v-for="trump in product.trumps">
                            <p><i class="fa-solid fa-check text--small text--success me-2 mt-1"></i>
                            {{ trump.text }}</p>
                        </li>
                    </ul>
                </div>
                <p v-else-if="product.description != null && product.description != ''" class="body--small" v-html="product.description"></p>
            </div>

            <div class="product__footer">
                <div>
                    <div class="product__price mb-2">
                        <!-- <p class="product__price--label">vanaf</p> -->
                        <p class="product__price--current">{{ product.formatted.use_price }}</p>
                        <p v-if="product.discount_active" class="product__price--old">{{ product.formatted.price }}</p>
                    </div>

                    <product-delivery-time
                        v-if="product.delivery_time"
                        :delivery-time="product.delivery_time"
                    ></product-delivery-time>
                </div>

                <button class="product__cart" @click.prevent.stop="cartClickHandler()">
                    <img src="/dist/assets/images/icons/cart.svg" width="20" v-if="!isLoading" uk-svg />
                    <div class="loader" v-else><i class="fa-solid fa-circle-notch fa-spin"></i></div>
                </button>
            </div>
        </a>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";
    import WishlistButton from "./WishlistButton";
    import ProductDeliveryTime from "./ProductDeliveryTime";

    export default {
        name: "ProductCard",
        components: {WishlistButton, ProductDeliveryTime},
        data(){
            return {
                isLoading: false,
                inWishlist: this.product.in_wishlist
            }
        },
        methods: {
            cartClickHandler()
            {
                if(this.product.type === "basart") {
                    EventBus.$emit("PRODUCT_CARD_CONFIGURATOR", this.product);
                }
                if(this.product.type === "giftcard") {
                    window.location = this.routes.giftcard_page ?? 'javascript:void(0)';
                }
                else {
                    this.isLoading = true;

                    window.axios.post(this.routes.products.addToCart, {product_id: this.product.id, quantity: 1})
                        .then(res => {
                            EventBus.$emit('CART_UPDATE', res.data.cart);
                            EventBus.$emit('PRODUCT_ADDED_MODAL', {product: this.product, quantity: 1});
                            this.isLoading = false;
                        })
                        .catch(error => {
                            let message = {
                                message: this.translations.add_error,
                                pos: "top-right",
                                status: "danger",
                                timeout: 3000,
                            };

                            EventBus.$emit('NOTIFY', message);
                            this.isLoading = false;
                        });
                }
            },
        },
        computed: {
          imageUrl() {
            return this.product?.image;
          },
        },
        props: {
            product: {
                type: Object|Array,
                required: true
            },
            routes: {
              type: Object|Array,
              required: true
            },
            translations: {
              type: Object|Array,
              required: true
            }
        }
    }
</script>

<style scoped>
    
</style>
