var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "form-custom" }, [
    _c(
      "ul",
      { staticClass: "sidebar", attrs: { "uk-accordion": "multiple: true" } },
      [
        _vm.categories.length > 0
          ? _c("filter-sidebar-categories", {
              attrs: {
                categories: _vm.categories,
                category: _vm.category,
                translations: _vm.translations,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.colors.length > 0
          ? _c("filter-sidebar-colors", {
              attrs: {
                colors: _vm.colors,
                translations: _vm.translations,
                "active-colors": _vm.activeColors,
              },
              model: {
                value: _vm.activeColors,
                callback: function ($$v) {
                  _vm.activeColors = $$v
                },
                expression: "activeColors",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.brands.length > 0
          ? _c("filter-sidebar-brands", {
              attrs: {
                brands: _vm.brands,
                translations: _vm.translations,
                "active-brands": _vm.activeBrands,
              },
              model: {
                value: _vm.activeBrands,
                callback: function ($$v) {
                  _vm.activeBrands = $$v
                },
                expression: "activeBrands",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("li", { staticClass: "uk-open" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "uk-accordion-content" }, [
            _c("div", { staticClass: "sidebar__price" }, [
              _c("p", { staticClass: "my-0 me-2" }, [_vm._v("€")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.minPrice,
                    expression: "minPrice",
                  },
                ],
                attrs: { type: "number", step: "0.01", name: "minp" },
                domProps: { value: _vm.minPrice },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.minPrice = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "my-0 mx-2" }, [_vm._v("tot")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.maxPrice,
                    expression: "maxPrice",
                  },
                ],
                attrs: { type: "number", step: "0.01", name: "maxp" },
                domProps: { value: _vm.maxPrice },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.maxPrice = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.pushQueryString.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "fa-solid fa-arrow-right" })]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm._l(_vm.sizes, function (sizegroup) {
          return _c("filter-sidebar-sizes-group", {
            key: sizegroup.id,
            attrs: { sizegroup: sizegroup, "active-sizes": _vm.activeSizes },
            model: {
              value: _vm.activeSizes,
              callback: function ($$v) {
                _vm.activeSizes = $$v
              },
              expression: "activeSizes",
            },
          })
        }),
        _vm._v(" "),
        _vm._l(_vm.dynamicfilters, function (filter) {
          return _c("filter-sidebar-dynamic-filters", {
            key: filter.id,
            attrs: {
              filter: filter,
              "active-filters": _vm.activeDynamicalFilters,
            },
            model: {
              value: _vm.activeDynamicalFilters,
              callback: function ($$v) {
                _vm.activeDynamicalFilters = $$v
              },
              expression: "activeDynamicalFilters",
            },
          })
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "uk-accordion-title", attrs: { href: "#" } },
      [
        _c("span", [_vm._v("Prijs")]),
        _vm._v(" "),
        _c("i", { staticClass: "fa-solid fa-chevron-down" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }