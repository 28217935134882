var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    Object.keys(_vm.products).length > 0
      ? _c(
          "div",
          [
            _c("products-overview", {
              attrs: {
                translations: _vm.translations,
                "init-products": _vm.products,
                routes: _vm.routes,
              },
            }),
          ],
          1
        )
      : _c("div", { staticClass: "uk-text-center" }, [
          _c("h4", { staticClass: "uk-text-center my-2" }, [
            _vm._v(_vm._s(_vm.translations.empty)),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "uk-button uk-button-primary uk-text-center mt-3",
              attrs: { href: _vm.routes.shop_page },
            },
            [_vm._v(_vm._s(_vm.translations.view_products))]
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }