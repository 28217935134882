var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-md-0 mb-5" }, [
    _c(
      "a",
      {
        staticClass: "product",
        attrs: { href: _vm.product.slug, title: _vm.product.name },
      },
      [
        _c(
          "span",
          { staticClass: "product__header" },
          [
            _c("img", {
              staticClass: "product__image",
              attrs: { src: _vm.imageUrl, alt: _vm.product.name },
            }),
            _vm._v(" "),
            _c("wishlist-button", {
              attrs: {
                "in-wishlist": _vm.product.in_wishlist,
                "product-id": _vm.product.id,
                routes: _vm.routes,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "product__info" }, [
          _c("p", { staticClass: "product__title" }, [
            _vm._v(_vm._s(_vm.product.name)),
          ]),
          _vm._v(" "),
          _vm.product.trumps && _vm.product.trumps.length
            ? _c("div", { staticClass: "check-list" }, [
                _c(
                  "ul",
                  { staticClass: "check-list body-small" },
                  _vm._l(_vm.product.trumps, function (trump) {
                    return _c("li", { staticClass: "mb-1" }, [
                      _c("p", [
                        _c("i", {
                          staticClass:
                            "fa-solid fa-check text--small text--success me-2 mt-1",
                        }),
                        _vm._v(
                          "\n                        " + _vm._s(trump.text)
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ])
            : _vm.product.description != null && _vm.product.description != ""
            ? _c("p", {
                staticClass: "body--small",
                domProps: { innerHTML: _vm._s(_vm.product.description) },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "product__footer" }, [
          _c(
            "div",
            [
              _c("div", { staticClass: "product__price mb-2" }, [
                _c("p", { staticClass: "product__price--current" }, [
                  _vm._v(_vm._s(_vm.product.formatted.use_price)),
                ]),
                _vm._v(" "),
                _vm.product.discount_active
                  ? _c("p", { staticClass: "product__price--old" }, [
                      _vm._v(_vm._s(_vm.product.formatted.price)),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.product.delivery_time
                ? _c("product-delivery-time", {
                    attrs: { "delivery-time": _vm.product.delivery_time },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "product__cart",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.cartClickHandler()
                },
              },
            },
            [
              !_vm.isLoading
                ? _c("img", {
                    attrs: {
                      src: "/dist/assets/images/icons/cart.svg",
                      width: "20",
                      "uk-svg": "",
                    },
                  })
                : _c("div", { staticClass: "loader" }, [
                    _c("i", {
                      staticClass: "fa-solid fa-circle-notch fa-spin",
                    }),
                  ]),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }